import Alpine from 'alpinejs';

import smoothScroll from '@js/parts/alpine/smoothScroll';
import headroom from '@js/parts/alpine/headroom';
import freeform from '@js/parts/alpine/freeform';
import tippy from '@js/parts/alpine/tippy';
import swiper from '@js/parts/alpine/swiper';

import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';

// 1st party plugins
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(focus);

// Components
Alpine.data("smoothScroll", smoothScroll);
Alpine.data("headroom", headroom);
Alpine.data('freeform', freeform);
Alpine.data('tippy', tippy);
Alpine.data("swiper", swiper);

window.Alpine = Alpine;
Alpine.start();
