/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./headroom";
 *   Alpine.data('headroom', headroom);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="headroom()" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */

import Headroom from "headroom.js";
import htmx from "htmx.org";

export default () => ({

  headroom: null,
  anchorList: null,
  header: null,

  init() {
    this.anchorList = this.$refs.anchorList;
    this.header = this.$refs.header;

    this.headroom = new Headroom(this.$el, {
      tolerance: {
        up: 0,
        down: 10
      },
      offset: {
        up: 0,
        down: 20
      },
    });
    this.headroom.init();

    if (typeof htmx === 'undefined') {
      return;
    }

    this.$nextTick(() => {
      htmx.on('htmx:beforeOnLoad', () => {
        if (this.headroom) {
          this.headroom.unpin();
          this.headroom.freeze();
        }
      });
      htmx.on('htmx:afterOnLoad', () => {
        let el = document.getElementById('content');
        if(el) {
          el.scrollIntoView({
            behavior: "smooth"
          });
          setTimeout(() => {
            // Run after 500 milliseconds
            if (this.headroom) {
              this.headroom.unfreeze();
            }
          }, 2000);
        }
      });

      if (this.header && this.anchorList) {
        this.onClassChange(this.header, (node) => {
          if (node.classList.contains('headroom--pinned')) {
            if (this.anchorList.classList.contains('sticky-nav')) {
              this.anchorList.classList.remove('sticky-nav');
              this.anchorList.classList.add('sticky-nav-open');
            }
          } else {
            if (this.anchorList.classList.contains('sticky-nav-open')) {
              this.anchorList.classList.remove('sticky-nav-open');
              this.anchorList.classList.add('sticky-nav');
            }
          }
        });
      }
    });
  },
  state(disable) {
    if (this.headroom) {
      if (disable) {
        this.headroom.freeze();
      } else {
        this.headroom.unfreeze();
      }
    }
  },
  onClassChange(element, callback) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          callback(mutation.target);
        }
      });
    });
    observer.observe(element, {attributes: true});
    return observer.disconnect;
  },
});
